import React from 'react';
import theme from "../../theme";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import FormGroup from "@mui/material/FormGroup";
import Slider from '@mui/material/Slider';
import {FormErrors, FormFieldError} from "../../App";
import FormLabel from "@mui/material/FormLabel";

const INCOME_MIN = 100000;
const INCOME_MAX = 1500000;

const PCT_MIN = 0;
const PCT_MAX = .15;

const formatAsPct = (num: number) => Number(num).toLocaleString(undefined,{style: 'percent', minimumFractionDigits:0});

interface IncomeConfigProps {
    income: {
        setIncome: React.Dispatch<React.SetStateAction<number>>;
        value: number;
    };
    expectedYearlyIncrease: {
        setExpectedYearlyIncrease: React.Dispatch<React.SetStateAction<number>>;
        value: number
    }
    formErrors: FormErrors;
}

const IncomeConfig: React.FC<IncomeConfigProps> = ({income, expectedYearlyIncrease, formErrors}) => {
    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',

        // These options are needed to round to whole numbers if that's what you want.
        //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
    });

    return (
        <>
            <div style={{textAlign: 'left', padding: theme.spacing(2)}}>
                <Typography variant="body2">
                    Configure your current and expected future income.
                </Typography>
            </div>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                    <FormLabel>Your current income: {formatter.format(income.value)}</FormLabel>
                    <FormGroup className="form-group">
                        <Slider
                            onChange={(e, value) => {
                                income.setIncome(value as number)
                            }}
                            value={income.value}
                            min={INCOME_MIN}
                            max={INCOME_MAX}
                            step={100000}
                        />
                        <FormFieldError message={formErrors.comparisonReturnPct} />
                    </FormGroup>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormLabel>Yearly income increase: {formatAsPct(expectedYearlyIncrease.value)}</FormLabel>
                    <FormGroup className="form-group">
                        <Slider
                            onChange={(e, value) => {
                                expectedYearlyIncrease.setExpectedYearlyIncrease(value as number)
                            }}
                            value={expectedYearlyIncrease.value}
                            min={PCT_MIN}
                            max={PCT_MAX}
                            step={.01}
                        />
                        <FormFieldError message={formErrors.comparisonReturnPct} />
                    </FormGroup>
                </Grid>
            </Grid>
        </>
    );
};

export default IncomeConfig