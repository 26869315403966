const YEARS_TO_MATURITY = 5;
export const YEARS = 20;
const STARTING_YEAR = new Date().getFullYear();

interface Syndication {
    id: string;
    startYear: number;
    amountInvested: number;
}

export interface PortfolioYear {
    year: number;
    totalEquity: number;
    passiveIncome: number;
    distributions?: number;
    syndications: Syndication[];
    invested?: boolean;
    reinvested?: boolean;
}

export const COMPARISON_CASH_COMES_FROM_LIQUIDATION = 'LIQUIDATION';
export const COMPARISON_CASH_COMES_FROM_CASH_FLOW = 'CASH_FLOW';
export type ComparisonCashComesFrom = typeof COMPARISON_CASH_COMES_FROM_CASH_FLOW | typeof COMPARISON_CASH_COMES_FROM_LIQUIDATION;

interface ComparisonInvestment {
    returnRate: number;
    liquidationRate: number;
    cashDistributionsComeFrom: ComparisonCashComesFrom;
}

interface PortfolioParameters {
    investPerYear: number;
    cocReturn: number;
    equityMultiple: number;
    stopInvestingAtYear: number;
    stopReinvestingAtYear: number;
    comparisonInvestment: ComparisonInvestment
}

export interface Portfolio {
    years: PortfolioYear[],
    parameters: PortfolioParameters;
}

const getId = () => {
    //Can change 7 to 2 for longer results.
    return (Math.random() + 1).toString(36).substring(7);
}

interface PortfolioCreateState {
    overflowBankAccount: number;
}

export const createPortfolio = (
    investPerYear: number,
    cocReturn: number,
    equityMultiple: number,
    stopInvestingAtYear: number = Infinity,
    stopReinvestingAtYear: number = Infinity,
    comparison: ComparisonInvestment,
): Portfolio => {
    const AAR = (equityMultiple - 1) / YEARS_TO_MATURITY;
    const appreciation = (AAR - cocReturn) * YEARS_TO_MATURITY;

    const portfolioYears: PortfolioYear[] = [];

    const state: PortfolioCreateState = {
        overflowBankAccount: 0,
    };

    const getTotalEquity = (syndications: Syndication[]) => {
        return syndications.reduce((total, current) => total + current.amountInvested , 0);
    };

    const getThisYearInvest = (curentYear: number): number => {
        return curentYear + 1 < stopInvestingAtYear ? investPerYear : 0;
    }

    const shouldReinvestDistributions = (year: number) => stopReinvestingAtYear > year + 1;

    for (let y = STARTING_YEAR, i = 0; y < STARTING_YEAR + YEARS; y++, i++){
        if (i === 0) {
            const thisYearInvest = getThisYearInvest(i);
            const thisYearDistributions = getThisYearInvest(i) * cocReturn;
            let thisYearPassiveIncome = thisYearDistributions;
            if (shouldReinvestDistributions(i)){
                state.overflowBankAccount += thisYearDistributions;
                thisYearPassiveIncome = 0;
            }

            portfolioYears.push({
                year: y,
                syndications: [
                    {
                        id: getId(),
                        startYear: y,
                        amountInvested: thisYearInvest,
                    }
                ],
                totalEquity: thisYearInvest,
                passiveIncome: thisYearPassiveIncome,
                invested: thisYearInvest > 0,
                distributions: thisYearDistributions,
                reinvested: shouldReinvestDistributions(i),
            });
        } else {
            // look for any syndications that have matured
            const lastYear: PortfolioYear | undefined = portfolioYears[i - 1];
            const reinvestFromLastYear = state.overflowBankAccount;
            state.overflowBankAccount = 0;

            if (y - STARTING_YEAR < YEARS_TO_MATURITY){
                // keep adding syndications
                const syndications = [
                    ...lastYear.syndications,
                    {
                        id: getId(),
                        startYear: y,
                        amountInvested: getThisYearInvest(i) + reinvestFromLastYear,
                    }
                ];
                const totalEquity = getTotalEquity(syndications);
                const thisYearDistributions = totalEquity * cocReturn;
                let passiveIncome = thisYearDistributions;

                if (shouldReinvestDistributions(i)){
                    state.overflowBankAccount += thisYearDistributions;
                    passiveIncome = 0;
                }

                portfolioYears.push({
                    year: y,
                    syndications,
                    totalEquity,
                    passiveIncome,
                    distributions: thisYearDistributions,
                    invested: getThisYearInvest(i) > 0,
                    reinvested: shouldReinvestDistributions(i),
                });
                continue;
            }

            if (!lastYear){
                continue;
            }
            const active: Syndication[] = [];
            const matured: Syndication[] = [];
            lastYear.syndications.forEach((syn) => {
                if (y - syn.startYear >= YEARS_TO_MATURITY){
                    matured.push(syn);
                } else {
                    active.push(syn);
                }
            });

            const reinvestDivisor = matured.length;

            const newSyndications: Syndication[] = matured.map((old) => ({
                id: getId(),
                amountInvested: (old.amountInvested * (1 + appreciation)) + getThisYearInvest(i) + (reinvestFromLastYear / reinvestDivisor),
                startYear: y,
            }));

            const syndications =  [
                ...active,
                ...newSyndications,
            ]

            const totalEquity = getTotalEquity(syndications);
            const thisYearDistributions = totalEquity * cocReturn;
            let passiveIncome = thisYearDistributions;

            if (shouldReinvestDistributions(i)){
                state.overflowBankAccount += thisYearDistributions;
                passiveIncome = 0;
            }

            portfolioYears.push({
                year: y,
                syndications,
                totalEquity,
                passiveIncome,
                distributions: thisYearDistributions,
                invested: getThisYearInvest(i) > 0,
                reinvested: shouldReinvestDistributions(i),
            });
        }
    }

    return {
        years: portfolioYears,
        parameters: {
            investPerYear,
            cocReturn,
            equityMultiple,
            stopInvestingAtYear,
            stopReinvestingAtYear,
            comparisonInvestment: comparison,
        },
    };
};

export const createLiquidationPortfolio = (
    investPerYear: number,
    yearlyReturn: number,
    liquidationRate: number,
    stopInvestingAtYear: number = Infinity,
    stopReinvestingDistributionsAtYear: number = Infinity,
) => {
    const getThisYearInvestment = (currentYear: number) => currentYear + 1 < stopInvestingAtYear ? investPerYear : 0;

    const years: PortfolioYear[] = []
    for (let i = 0; i < YEARS; i++){
        if (i === 0){
            // passive income needs to be 0 because we're always liquidating the gains from last year
            years.push(
                {
                    year: STARTING_YEAR,
                    totalEquity: investPerYear,
                    passiveIncome: 0,
                    syndications: [],
                }
            )
        } else {
            const lastYear = years[i - 1];
            if (lastYear?.totalEquity) {
                let totalFromLastYear = lastYear.totalEquity * (1 + yearlyReturn);
                let passiveIncome = 0;

                // if we're not reinvesting distributions, take 4% from last year
                if (i + 1 >=  stopReinvestingDistributionsAtYear){
                    passiveIncome = totalFromLastYear * liquidationRate;
                    totalFromLastYear -= passiveIncome;
                }

                years.push(
                    {
                        year: STARTING_YEAR + i,
                        totalEquity: totalFromLastYear + getThisYearInvestment(i),
                        passiveIncome,
                        syndications: []
                    }
                )
            }
        }
    }

    return years;
}

export const getEquityMultipleForSimpleYearlyReturn = (rate: number) => {
    return Math.pow((1+rate), YEARS_TO_MATURITY);
}