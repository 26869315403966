import React from 'react';
import {LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip, Legend} from 'recharts';
import {Portfolio, PortfolioYear} from "../../create-portfolio";
import {useTheme} from "@mui/material";
import Typography from "@mui/material/Typography";
import {getAbbreviatedCurrencyValue} from "../../util";
import Grid from "@mui/material/Grid";
import './index.css';

interface ChartsProps {
    portfolio: Portfolio;
    comparisonPortfolio: PortfolioYear[],
}

/*
Period (start-of-year to end-of-2022)	Average annual S&P 500 return
5 years (2018-2022)	7.51%
10 years (2013-2022)	10.41%
20 years (2003-2022)	7.64%
30 years (1993-2022)	7.52%

 */

const Charts: React.FC<ChartsProps> = ({portfolio, comparisonPortfolio}) => {
    const theme = useTheme();

    const passiveIncomeData: Record<string, any>[] = portfolio.years.map((year) => {
        return {
            name: year.year,
            'Conveyor Belt deals': year.passiveIncome,
        }
    });

    const totalEquityData: Record<string, any>[] = portfolio.years.map((year) => {
        return {
            name: year.year,
            'Conveyor Belt deals': year.totalEquity,
        }
    });

    comparisonPortfolio.forEach((year, i) => {
        totalEquityData[i]['Comparison Investment'] = year.totalEquity;
        passiveIncomeData[i]['Comparison Investment'] = year.passiveIncome;
    });


    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    });

    const tooltipFormatter = (value: number) => {
        return formatter.format(value || 0);
    }


    return (
        <Grid container spacing={2}>
            <Grid item xs={12} lg={6} className="chart-container">
                <Typography variant="h4">Total Equity</Typography>
                <LineChart width={400} height={400} data={totalEquityData}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis tickFormatter={getAbbreviatedCurrencyValue} />
                    <Tooltip formatter={tooltipFormatter} />
                    <Legend />
                    <Line
                        type="monotone"
                        dataKey="Conveyor Belt deals"
                        stroke={theme.palette.primary.main}
                        isAnimationActive={false}
                    />
                    <Line
                        type="monotone"
                        dataKey="Comparison Investment"
                        stroke="#666666"
                        isAnimationActive={false}
                    />
                </LineChart>
            </Grid>
            <Grid item xs={12} lg={6} className="chart-container">
                <Typography variant="h4">Passive Income</Typography>
                <LineChart width={400} height={400} data={passiveIncomeData}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis tickFormatter={getAbbreviatedCurrencyValue} />
                    <Tooltip formatter={tooltipFormatter} />
                    <Legend />
                    <Line
                        type="monotone"
                        dataKey="Conveyor Belt deals"
                        stroke={theme.palette.secondary.main}
                        isAnimationActive={false}
                    />
                    <Line
                        type="monotone"
                        dataKey="Comparison Investment"
                        stroke="#666666"
                        isAnimationActive={false}
                    />
                </LineChart>
            </Grid>
        </Grid>
    )
};

export default Charts;