export const getAbbreviatedCurrencyValue = (value: number): string => {
    let number = value;
    let suffix = '';

    if (value > 500 * 1000) {
        suffix = 'M';
        number = value / 1000000;
    } else if (value > 1000) {
        suffix = 'K'
        number = value / 1000;
    }

    return `$${number}${suffix}`;
};

export const roundPercentage = (pct: number): number => {
    return Math.round(pct * 100 * 100) / 100;
}