import React from 'react';
import {useTheme} from '@mui/material';

const SIZE_HALF = 'half';
const SIZE_ONE = 'one';
const SIZE_TWO = 'two';
const SIZE_THREE = 'three';
const SIZE_FOUR = 'four';
type SpacerSize = typeof SIZE_HALF | typeof SIZE_ONE | typeof SIZE_TWO | typeof SIZE_THREE | typeof SIZE_FOUR;

const sizeMap = {
    [SIZE_HALF]: .5,
    [SIZE_ONE]: 1,
    [SIZE_TWO]: 2,
    [SIZE_THREE]: 3,
    [SIZE_FOUR]: 4,
}

interface SpacerProps {
    size: SpacerSize;
    vertical?: boolean;
}

const Spacer: React.FC<SpacerProps> = ({size, vertical = false}) => {
    const theme = useTheme();
    const spacing = theme.spacing(sizeMap[size]);

    return (
        <div
            role="presentation"
            style={{
                display: vertical ? 'block' : 'inline-block',
                marginRight: vertical ? 0 : spacing,
                marginTop: vertical ? spacing: 0,
            }}
        />
    );
};

export default Spacer;