import React, { useState, useEffect, cloneElement, ReactElement } from 'react';
import './index.css';

interface FadeInChildrenProps {
    delay?: number;
}

const FadeInChildren: React.FC<React.PropsWithChildren<FadeInChildrenProps>> = ({children, delay: delayOffset = 500}) => {
    const [childElements, setChildElements] = useState<ReactElement[]>([]);

    useEffect(() => {
        // Start animating the child elements
        animateChildren();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const animateChildren = () => {
        const newElements = React.Children.map(children, (child, index) => {
            const delay = index * delayOffset; // Adjust the delay based on your needs

            return cloneElement(child as ReactElement, {
                style: {
                    ...((child as ReactElement).props.style || {}),
                    animationDelay: `${delay}ms`,
                },
                className: 'animated-child',
            });
        });

        if (newElements) {
            setChildElements(newElements);
        }
    };

    return <div className="animation-container">{childElements}</div>;
}

export default FadeInChildren;