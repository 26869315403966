import React, {useEffect} from 'react';
import {
    COMPARISON_CASH_COMES_FROM_LIQUIDATION,
    createLiquidationPortfolio,
    createPortfolio,
    getEquityMultipleForSimpleYearlyReturn,
    Portfolio,
    PortfolioYear,
    YEARS
} from "../create-portfolio";
import FadeInChildren from "../FadeInChildren";
import Typography from "@mui/material/Typography";
import './index.css';
import {useTheme} from "@mui/material";
import Container from "@mui/material/Container";
import Charts from "./Charts";
import Spacer from "../Spacer";
import {roundPercentage} from "../util";
import Footer from "../Footer";
import Grid from "@mui/material/Grid";

const DEFAULT_NAME = 'Investor';

interface PortfolioTableProps {
    portfolio: Portfolio;
    name?: string
}

let tableHasRendered = false; // hacky way to make sure we're only setting the animation once


const Emphasis: React.FC<React.PropsWithChildren> = ({children}) => {
    const theme = useTheme();

    return (
        <span style={{
            color: theme.palette.primary.main,
            fontWeight: 600,
        }}>
            {children}
        </span>
    );
}

const MetricTitle: React.FC<React.PropsWithChildren> = ({children}) => {
    return (
        <p style={{fontWeight: 700, margin: 0}}>{children}</p>
    )
}

const Parameter: React.FC<React.PropsWithChildren> = ({children}) => {
    return (
        <div className="parameter" style={{border: `1px solid #DDDDDD`}}>
            {children}
        </div>
    )
}

const getDelta = (reNumber: number, comparisonNumber: number) => {
    if (reNumber === comparisonNumber) {
        return 0; // handles 0/0
    }
    return roundPercentage(comparisonNumber / reNumber - 1);
}

const DeltaValue: React.FC<{delta: number}> = ({delta}) => {
    if (delta === 0) {
        return null;
    }

    let color = '#777777';
    if (delta > 0) {
        color = 'green';
    }
    if (delta < 0) {
        color = 'red';
    }
    return (
        <span style={{color}}>({delta > 0 ? '+' : ''}{delta}%)</span>
    )
}

const PortfolioTable: React.FC<PortfolioTableProps> = ({portfolio, name = DEFAULT_NAME}) => {
    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',

        // These options are needed to round to whole numbers if that's what you want.
        //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
    });
    const theme = useTheme();

    // const derivativeMetrics = useDerivativePortfolioMetrics(portfolio);
    const {
        investPerYear,
        cocReturn,
        equityMultiple,
        stopInvestingAtYear,
        stopReinvestingAtYear,
        comparisonInvestment,
    } = portfolio.parameters;

    let comparisonPortfolio: PortfolioYear[];

    if (comparisonInvestment.cashDistributionsComeFrom === COMPARISON_CASH_COMES_FROM_LIQUIDATION) {
        comparisonPortfolio = createLiquidationPortfolio(
            investPerYear,
            comparisonInvestment.returnRate,
            comparisonInvestment.liquidationRate,
            stopInvestingAtYear,
            stopReinvestingAtYear,
        );
    } else {
        const completeComparisonPortfolio = createPortfolio(
            investPerYear,
            comparisonInvestment.liquidationRate,
            getEquityMultipleForSimpleYearlyReturn(comparisonInvestment.returnRate),
            stopInvestingAtYear,
            stopReinvestingAtYear,
            {
                returnRate: 0,
                liquidationRate: 0,
                cashDistributionsComeFrom: COMPARISON_CASH_COMES_FROM_LIQUIDATION,
            }
        );
        comparisonPortfolio = completeComparisonPortfolio.years;
    }

    const displayCoc = Math.round(cocReturn * 100 * 100) / 100;

    useEffect(() => {
        return () => {
            tableHasRendered = true;
        }
    }, [])

    const lastYear: PortfolioYear | undefined = portfolio.years.slice(-1)[0];

    const getWhenStopReinvestingDistributionsText = () => {
        if (stopReinvestingAtYear === 0){
            return 'immediately';
        }
        if (stopReinvestingAtYear === Infinity) {
            return 'never';
        }

        return `year ${stopReinvestingAtYear}`;
    }


        const getStopReinvestingText = (action: string, year: number) => {
        if (year === Infinity) {
            return `Never stopped ${action}`;
        }
        if (year === 0) {
            return `Never started ${action}`;
        }
        return `Stopped ${action} in year ${year}`
    }

    return (
        <Container className="container-with-header">
            <FadeInChildren delay={tableHasRendered ? 0 : 1000}>
                <div>
                    <Typography variant="h4">{name}'s {YEARS} year plan</Typography>
                    <Spacer size="four" vertical />
                </div>
                <div className="metrics-copy">
                    <Grid container spacing={2} className="print-flatten">
                        <Grid item xs={12} sm={6} lg={3}>
                            <Parameter><MetricTitle>Amount invested per year:</MetricTitle> {formatter.format(investPerYear)}</Parameter>
                        </Grid>
                        <Grid item xs={12} sm={6} lg={3}>
                            <Parameter><MetricTitle>Cash-on-cash-return:</MetricTitle> {displayCoc}%</Parameter>
                        </Grid>
                        <Grid item xs={12} sm={6} lg={3}>
                            <Parameter><MetricTitle>Equity multiple:</MetricTitle> {equityMultiple}</Parameter>
                        </Grid>
                        <Grid item xs={12} sm={6} lg={3}>
                            <Parameter><MetricTitle>{getStopReinvestingText(`investing ${formatter.format(investPerYear)}`, stopInvestingAtYear)}</MetricTitle></Parameter>
                        </Grid>
                        <Grid item xs={12} sm={6} lg={3}>
                            <Parameter><MetricTitle>{getStopReinvestingText('reinvesting distributions', stopReinvestingAtYear)}</MetricTitle></Parameter>
                        </Grid>
                    </Grid>
                    <Spacer size="three" vertical />
                </div>
                <div style={{borderRadius: 4, border: `1px solid ${theme.palette.primary.main}`, padding: theme.spacing(1)}}>
                    <Typography variant="body1" style={{fontWeight: 700}}>Your results:</Typography>
                    <Spacer size="two" vertical />
                    <Typography variant="body1">
                        By year 20, your equity position is <Emphasis>{formatter.format(lastYear?.totalEquity || 0)}</Emphasis> and your cash flow distributions are <Emphasis>{formatter.format(lastYear.passiveIncome || 0)}</Emphasis> per year!
                    </Typography>
                </div>
                <div style={{textAlign: 'left'}}>
                    <Spacer size="four" vertical />
                    <Charts portfolio={portfolio} comparisonPortfolio={comparisonPortfolio} />
                    <div style={{fontStyle: "italic"}}>
                        <Typography variant="body2">
                            The charts above compare your total equity and cash flow in real estate syndications to an alternative investment <i>(stocks, a high-yield savings account, etc)</i> that compounds at <Emphasis>{roundPercentage(portfolio.parameters.comparisonInvestment.returnRate)}%</Emphasis>.
                        </Typography>
                        <Typography variant="body2">
                            The passive income figure for the high-yield savings account comes from taking {roundPercentage(comparisonInvestment.liquidationRate)}% per year from equity, which in this simulation begins when you stop reinvesting syndication distributions (<Emphasis>{getWhenStopReinvestingDistributionsText()}</Emphasis>).
                        </Typography>
                    </div>
                </div>
                <Spacer size="four" vertical />
                <table>
                    <thead>
                    <tr>
                        <th></th>
                        <th colSpan={2}>Total Equity</th>
                        <th colSpan={2}>Passive Income</th>
                    </tr>
                    <tr>
                        <th>Year</th>
                        <th>Conveyor Belt Deals</th>
                        <th>Comparison</th>
                        <th>Conveyor Belt Deals</th>
                        <th>Comparison</th>
                    </tr>
                    </thead>
                    {portfolio && portfolio.years.map((year, idx) => {
                        const reEquity = year.totalEquity || 0;
                        const rePassiveIncome = year.passiveIncome || 0;
                        const reDistributions = year.distributions || 0;
                        const comparisonEquity = comparisonPortfolio[idx].totalEquity || 0;
                        const comparisonPassiveIncome = comparisonPortfolio[idx].passiveIncome || 0;
                        const comparisonEquityDelta = getDelta(reEquity, comparisonEquity);
                        const comparisonIncomeDelta = getDelta(rePassiveIncome, comparisonPassiveIncome);

                        return (
                            <tr>
                                <td>{year.year}</td>
                                <td>{formatter.format(reEquity)}</td>
                                <td>
                                    {formatter.format(comparisonEquity)}
                                    <Spacer size="one" />
                                    <DeltaValue delta={comparisonEquityDelta} />
                                </td>
                                <td>
                                    {formatter.format(rePassiveIncome)}
                                    {rePassiveIncome === 0 && (
                                        <div style={{color: '#AAAAAA'}}>
                                            ({formatter.format(reDistributions)} distributed)
                                        </div>
                                    )}
                                </td>
                                <td>
                                    {formatter.format(comparisonPassiveIncome)}
                                    <Spacer size="one" />
                                    <DeltaValue delta={comparisonIncomeDelta} />
                                </td>
                                <td></td>
                            </tr>
                        );
                    })}
                </table>
                <Footer />
            </FadeInChildren>
        </Container>
    );
};

export default PortfolioTable;