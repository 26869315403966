import React, {ChangeEvent, SyntheticEvent, useEffect, useState} from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import FormGroup from "@mui/material/FormGroup";
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import RadioGroup from "@mui/material/RadioGroup";
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Tooltip from "@mui/material/Tooltip";
import {COMPARISON_CASH_COMES_FROM_LIQUIDATION, COMPARISON_CASH_COMES_FROM_CASH_FLOW, ComparisonCashComesFrom, createPortfolio, Portfolio} from "./create-portfolio";
import './App.css';
import Container from "@mui/material/Container";
import {AppBar, SlideProps, Toolbar, useScrollTrigger, useTheme} from "@mui/material";
import { ThemeProvider } from '@mui/material/styles';
import theme from './theme';
import Slide from '@mui/material/Slide';
import Spacer from "./Spacer";
import PortfolioTable from "./PortfolioTable";
import Grid from "@mui/material/Grid";
import Footer from './Footer';
import IconButton from "@mui/material/IconButton";
import {ExpandLess, ExpandMore} from "@mui/icons-material";
import {useContactEmail} from "./hooks/useContactEmail";
import IncomeConfig from "./forms/IncomeConfig";

import {FEATURE_FLAGS} from "./feature-flags";

const COC = 7;
const EQUITY_MULTIPLE = 1.8;
const DEFAULT_INVEST_PER_YEAR = 100 * 1000;
const DEFAULT_STOP_INVESTING_AT = 15;

const DEFAULT_COMPARISON_RETURN_PCT = 7.5;
const DEFAULT_COMPARISON_LIQUIDATION_RATE_PCT = 4;

const DEFAULT_COMPARISON_CASH_COMES_FROM = COMPARISON_CASH_COMES_FROM_LIQUIDATION;

const DEFAULT_INCOME = 300000;
const DEFAULT_EXPECTED_YEARLY_INCOME_INCREASE = .05;

const getComparisonHelpTextForOrigin = (origin: ComparisonCashComesFrom) => {
    if (origin === COMPARISON_CASH_COMES_FROM_CASH_FLOW){
        return 'This is the yearly rate at which your investment cash flows.';
    }

    return 'This is the rate at which you\'ll liquidate your portfolio per year for passive income.'
};

const HighlightGreen: React.FC<React.PropsWithChildren> = ({children}) => (
    <div style={{padding: theme.spacing(2), backgroundColor: theme.palette.secondary.light}}>
        {children}
    </div>
);

interface HideOnScrollProps extends SlideProps{
    children: React.ReactElement;
}

const HideOnScroll = ({children, ...rest}: HideOnScrollProps) => {
    const trigger = useScrollTrigger();

    return (
        <Slide appear={false} direction="down" in={!trigger} {...rest}>
            {children}
        </Slide>
    );
}

interface EnterYearControlProps {
    value: number;
    onChange: (value: number) => void;
    label: string;
    tooltip?: string;
}

const EnterYearControl: React.FC<EnterYearControlProps> = ({value, onChange, label, tooltip}) => {
    const [lastValueStr, setLastValueStr] = useState(`${value === Infinity ? '' : value}`);
    const [never, setNever] = useState<boolean>(value === Infinity);

    const handleYearChange = (event: ChangeEvent<HTMLInputElement>) => {
        const newValueStr = event.target.value;
        setLastValueStr(newValueStr);
    };

    const handleNeverChange = (event: ChangeEvent<HTMLInputElement>) => {
        const newValue = event.target.checked;
        setNever(newValue);
    };

    useEffect(() => {
        let finalValue = 0;
        const textYearsVal = parseInt(lastValueStr);

        if (never || isNaN(textYearsVal)) {
            finalValue = Infinity;
        } else {
            finalValue = textYearsVal;
        }

        onChange(finalValue);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [never, lastValueStr])

    const getYearValue = () => {
        if (value === Infinity) {
            return lastValueStr;
        }
        return `${value}`;
    };

    return (
        <>
            <Tooltip title={tooltip}>
                <TextField label={label} onChange={handleYearChange} value={getYearValue()} disabled={never} />
            </Tooltip>
            <FormControlLabel
                control={<Checkbox onChange={handleNeverChange} checked={never} />}
                label="Never"
            />
        </>
    )
}

export const FormFieldError: React.FC<{message?: string}> = ({message = ''}) => {
    const theme = useTheme();

    return (
        <div style={{
            minHeight: theme.spacing(3),
            color: theme.palette.error.main,
        }}>
            {message}
        </div>
    );
}

export interface FormErrors {
    amountInvestedPerYear?: string;
    cocReturn?: string;
    equityMultiple?: string;
    stopInvestingYear?: string;
    stopInvestingDistributionsYear?: string;
    comparisonReturnPct?: string;
    comparisonLiquidationRatePct?: string;
}

interface LoginFormProps {
    onSubmit: (email: string) => void;
    loading: boolean;
}

const LoginForm: React.FC<LoginFormProps> = ({onSubmit, loading}) => {
    const [email, setEmail] = useState<string>('');
    const [error, setError] = useState<string>();

    // https://stackoverflow.com/questions/39356826/how-to-check-if-it-a-text-input-has-a-valid-email-format-in-reactjs
    // eslint-disable-next-line no-useless-escape
    let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    const handleSubmit = (e: SyntheticEvent) => {
        e.preventDefault();

        if (email && re.test(email)){
            // valid
            setError(undefined);
            onSubmit(email);
        } else {
            setError('Please provide a valid email.');
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            <FormGroup className="form-group">
                <TextField
                    label="Email"
                    onChange={(e) => {setEmail(e.target.value)}}
                    value={email}
                    error={!!error}
                />
                <FormFieldError message={error} />
                {loading ? (
                    <img style={{height: 45}} src="loading.svg" alt="Loading" />
                ) : (
                    <Button type="submit">Submit</Button>
                )}
            </FormGroup>
        </form>
    );
};

function App() {
    const [contactEmail, setContactEmail, contactEmailLoading] = useContactEmail();

    const [amountInvestedPerYear, setAmountInvestedPerYear] = useState<string>(`${DEFAULT_INVEST_PER_YEAR}`);
    const [cocReturnPct, setCocReturnPct] = useState<string>(`${COC}`);
    const [equityMultiple, setEquityMultiple] = useState<string>(`${EQUITY_MULTIPLE}`);
    const [stopInvestingYear, setStopInvestingYear] = useState(DEFAULT_STOP_INVESTING_AT);
    const [reinvestDistributions, setReinvestDistributions] = useState<boolean>(false);
    const [stopReinvestingDistributionsYear, setStopReinvestingDistributionsYear] = useState(0)
    const [portfolio, setPortfolio] = useState<Portfolio>();
    const [page, setPage] = useState(0);
    const [formErrors, setFormErrors] = useState<FormErrors>({});
    const [name, setName] = useState<string>();

    const [editInvestingParamsOpen, setEditInvestingParamsOpen] = useState<boolean>(false);
    const [editComparisonOpen, setEditComparisonOpen] = useState<boolean>(false);
    const [editIncomeOpen, setEditIncomeOpen] = useState<boolean>(false);
    const [comparisonReturnPct, setComparisonReturnPct] = useState<string>(`${DEFAULT_COMPARISON_RETURN_PCT}`)
    const [comparisonLiquidationRatePct, setComparisonLiquidationRatePct] = useState<string>(`${DEFAULT_COMPARISON_LIQUIDATION_RATE_PCT}`)
    const [comparisonCashComesFrom, setComparisonCashComesFrom] = useState<ComparisonCashComesFrom>(DEFAULT_COMPARISON_CASH_COMES_FROM);
    const [income, setIncome] = useState<number>(DEFAULT_INCOME);
    const [expectedIncomeIncrease, setExpectedIncomeIncrease] = useState<number>(DEFAULT_EXPECTED_YEARLY_INCOME_INCREASE);

    const handleSubmit = () => {
        const valid = validateForm();

        if (!valid){
            return;
        }

        const amountVal = amountInvestedPerYear ? parseInt(amountInvestedPerYear) : 0;
        const cocReturnPctVal = cocReturnPct ? parseInt(cocReturnPct) : 0;
        const equityMultipleVal = equityMultiple ? parseFloat(equityMultiple): 0;

        const comparison = {
            returnRate: comparisonReturnPct ? parseInt(comparisonReturnPct) / 100 : 0,
            liquidationRate: comparisonLiquidationRatePct ? parseInt(comparisonLiquidationRatePct) / 100 : 0,
            cashDistributionsComeFrom: comparisonCashComesFrom,
        };

        const finalStopReinvestingDistributions = reinvestDistributions ? stopReinvestingDistributionsYear : 0;

        setPortfolio(createPortfolio(amountVal, cocReturnPctVal / 100, equityMultipleVal, stopInvestingYear, finalStopReinvestingDistributions, comparison));
        setPage(1);
    };

    const validateForm = (): boolean => {
        const MINIMUM_YEARS_INVESTING = 6;

        const newErrors: FormErrors = {};
        let valid = true;

        const validatePositiveMetric = (metric: string, metricName: string, metricPropName: keyof FormErrors) => {
            const val = parseInt(metric);
            if (isNaN(val)){
                newErrors[metricPropName] = `${metricName} must be a number.`;
                valid = false;
            } else if (val <= 0) {
                newErrors[metricPropName] = `${metricName} must be greater than 0.`;
                valid = false;
            }
        }

        validatePositiveMetric(amountInvestedPerYear, 'Amount invested per year', 'amountInvestedPerYear');
        validatePositiveMetric(cocReturnPct, 'Cash-on-cash return', 'cocReturn');
        validatePositiveMetric(equityMultiple, 'Equity multiple', 'equityMultiple');

        if (stopInvestingYear < MINIMUM_YEARS_INVESTING) {
            newErrors.stopInvestingYear = `You can't stop investing before year 6.`;
            valid = false;
        }

        setFormErrors(newErrors);

        return valid;
    };

    const renderDescriptionSection = () => (
        <div>
            <Typography variant="h1" color={theme.palette.primary.main}>
                Conveyor Belt Wealth Simulator
            </Typography>
            <Typography variant="body1">
                See how real estate syndications can <b>accelerate your wealth</b> with this free simulator!
            </Typography>
            <Spacer size="three" vertical />
            <Typography variant="body2">
                <b>Conveyor belt wealth</b> is a framework that helps an investor supercharge their return through consistently investing in historically stable, cash-flowing real estate syndications.
            </Typography>
            <Spacer size="three" vertical />
            <HighlightGreen>
                <Typography variant="body1">
                    <strong>
                        How much can you make in 20 years on the Conveyor Belt?
                    </strong>
                </Typography>
            </HighlightGreen>
        </div>
    )

    const renderBackgroundDiv = () => (
        <div style={{
            position: 'absolute',
            backgroundImage: "url('https://ik.imagekit.io/knbfzwdru/conveyor_h2O99TjJM.png?updatedAt=1700176895783')",
            top: 0,
            left: 0,
            zIndex: 0,
            width: '100%',
            height: '100%',
            opacity: 0.1
        }} />
    );

    const renderLogin = () => (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                minHeight: '100vh',
                flexDirection: 'row',
            }}
        >
            {renderBackgroundDiv()}
            <Container style={{
                maxWidth: 700,
                flex: 1,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                position: 'relative',
                zIndex: 10,
                backgroundColor: theme.palette.background.default,
                borderRadius: theme.spacing(1),
                padding: theme.spacing(3),
            }}>
                {renderDescriptionSection()}
                <Spacer size="four" vertical />
                <Typography variant="body1">
                    To get started, enter your email.
                </Typography>
                <Spacer size="four" vertical />
                <Grid item xs={12} sm={6}>
                    <LoginForm onSubmit={setContactEmail} loading={contactEmailLoading} />
                </Grid>
            </Container>
        </Box>
    )

    const renderForm = () => {
        return (
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    minHeight: '100vh',
                    flexDirection: 'column',
                    paddingTop: theme.spacing(3),
                }}
            >
                {renderBackgroundDiv()}
                <Container style={{
                    maxWidth: 700,
                    flex: 1,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                    position: 'relative',
                    zIndex: 10,
                    backgroundColor: theme.palette.background.default,
                    borderRadius: theme.spacing(1),
                }}>
                    {renderDescriptionSection()}
                    <Spacer size="two" vertical />
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <FormGroup>
                                <TextField
                                    label="Your name"
                                    onChange={(e) => {setName(e.target.value)}} value={name}
                                    helperText="We don't save this information."
                                />
                            </FormGroup>
                            <Spacer size="two" vertical />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormGroup className="form-group">
                                <TextField label="Amount you want to invest per Year" onChange={(e) => {setAmountInvestedPerYear(e.target.value)}} value={amountInvestedPerYear} />
                                <FormFieldError message={formErrors.amountInvestedPerYear} />
                            </FormGroup>
                        </Grid>
                    </Grid>
                    <Box sx={{
                        border: '1px solid #EEEEEE',
                        padding: theme.spacing(1),
                        width: '100%',
                        borderRadius: theme.spacing(1),
                    }}>
                        <div>
                            <IconButton onClick={() => setEditInvestingParamsOpen(!editInvestingParamsOpen)} className="collapsible-cell-button">
                                {editInvestingParamsOpen ? <ExpandLess /> : <ExpandMore />}
                            </IconButton>
                            <Button onClick={() => setEditInvestingParamsOpen(!editInvestingParamsOpen)}>Change investment parameters</Button>
                        </div>
                        {editInvestingParamsOpen && (
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6}>
                                    <FormGroup className="form-group">
                                        <EnterYearControl
                                            value={stopInvestingYear}
                                            onChange={setStopInvestingYear}
                                            label="Stop investing in year"
                                            tooltip="This is the year you'll stop putting money in...but the conveyor belt keeps rolling."
                                        />
                                        <FormFieldError message={formErrors.stopInvestingYear} />
                                    </FormGroup>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <FormGroup className="form-group">
                                        <Tooltip title="Cash-on-cash return is a metric of how much cash flow you receive per year in an investment. It's a measure of cash flow per year divided by the amount of money you put in.">
                                            <TextField label="Cash-on-cash return" onChange={(e) => {setCocReturnPct(e.target.value)}} value={cocReturnPct} helperText="Enter a percentage, like 7 for 7%" />
                                        </Tooltip>
                                        <FormFieldError message={formErrors.cocReturn} />
                                    </FormGroup>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <FormGroup className="form-group">
                                        <Tooltip title="Equity multiple is your total return divided by your initial investment. An investment of $100 that yields $200 total has an equity multiple of 2.">
                                            <TextField label="Equity multiple" onChange={(e) => {setEquityMultiple(e.target.value)}} value={equityMultiple} />
                                        </Tooltip>
                                        <FormFieldError message={formErrors.equityMultiple} />
                                    </FormGroup>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <FormGroup className="form-group">
                                        <Tooltip title="If you select this, you'll take your yearly cash flow distributions and re-invest them the next year, instead of using them as passive income.">
                                            <FormControlLabel
                                                control={<Checkbox onChange={(e) => {setReinvestDistributions(e.target.checked)}} checked={reinvestDistributions} />}
                                                label="Reinvest cash flow distributions?"
                                            />
                                        </Tooltip>
                                    </FormGroup>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    {reinvestDistributions && (
                                        <FormGroup className="form-group">
                                            <EnterYearControl value={stopReinvestingDistributionsYear} onChange={setStopReinvestingDistributionsYear} label="Stop reinvesting distributions in year" />
                                        </FormGroup>
                                    )}
                                </Grid>
                            </Grid>
                        )}
                    </Box>

                    {FEATURE_FLAGS.incomeConfig && (
                        <Box sx={{
                            border: '1px solid #EEEEEE',
                            padding: theme.spacing(1),
                            width: '100%',
                            borderRadius: theme.spacing(1),
                        }}>
                            <div>
                                <IconButton onClick={() => setEditIncomeOpen(!editIncomeOpen)} className="collapsible-cell-button">
                                    {editIncomeOpen ? <ExpandLess /> : <ExpandMore />}
                                </IconButton>
                                <Button onClick={() => setEditIncomeOpen(!editIncomeOpen)}>Edit income</Button>
                            </div>
                            {editIncomeOpen && (
                                <IncomeConfig
                                    income={{value: income, setIncome}}
                                    expectedYearlyIncrease={{value: expectedIncomeIncrease, setExpectedYearlyIncrease: setExpectedIncomeIncrease}}
                                    formErrors={formErrors}
                                />
                            )}
                        </Box>
                    )}

                    <Box sx={{
                        border: '1px solid #EEEEEE',
                        padding: theme.spacing(1),
                        width: '100%',
                        borderRadius: theme.spacing(1),
                    }}>
                        <div>
                            <IconButton onClick={() => setEditComparisonOpen(!editComparisonOpen)} className="collapsible-cell-button">
                                {editComparisonOpen ? <ExpandLess /> : <ExpandMore />}
                            </IconButton>
                            <Button onClick={() => setEditComparisonOpen(!editComparisonOpen)}>Edit comparison investment</Button>
                        </div>
                        {editComparisonOpen && (
                            <>
                                <div style={{textAlign: 'left', padding: theme.spacing(2)}}>
                                    <Typography variant="body2">
                                        The comparison investment will let us see how the Conveyor Belt framework with real estate syndications stacks up against other kinds of investments.
                                    </Typography>
                                </div>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={6}>
                                        <FormGroup className="form-group">
                                            <TextField label="Return percentage" onChange={(e) => {setComparisonReturnPct(e.target.value)}} value={comparisonReturnPct} helperText="Enter a percentage, like 7 for 7%" />
                                            <FormFieldError message={formErrors.comparisonReturnPct} />
                                        </FormGroup>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <FormGroup className="form-group">
                                            <TextField label="Rate of Cash Distribution" onChange={(e) => {setComparisonLiquidationRatePct(e.target.value)}} value={comparisonLiquidationRatePct} helperText={getComparisonHelpTextForOrigin(comparisonCashComesFrom)} />
                                            <FormFieldError message={formErrors.comparisonReturnPct} />
                                        </FormGroup>
                                    </Grid>
                                    <Grid item xs={12} sm={6} style={{display: 'flex', justifyContent: 'start'}}>
                                        <FormControl>
                                            <FormLabel id="comparison-cash-comes-from-label">Cash Distributions come from</FormLabel>
                                            <RadioGroup
                                                aria-labelledby="comparison-cash-comes-from-label"
                                                name="comparison-cash-comes-from"
                                                value={comparisonCashComesFrom}
                                                onChange={(e) => {setComparisonCashComesFrom(e.target.value as ComparisonCashComesFrom)}}
                                            >
                                                <FormControlLabel value={COMPARISON_CASH_COMES_FROM_LIQUIDATION} control={<Radio />} label="Liquidation" />
                                                <FormControlLabel value={COMPARISON_CASH_COMES_FROM_CASH_FLOW} control={<Radio />} label="Cash Flow" />
                                            </RadioGroup>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} style={{textAlign: 'left', color: '#777777'}}>
                                        <i>Liquidation</i> means that the passive income you make is from liquidating assets (e.g. a stock portfolio). <i>Cash flow</i> means that passive income comes from regular operation of a business (e.g. a rental or other small business).
                                    </Grid>
                                </Grid>
                            </>
                        )}
                    </Box>
                    <Spacer size="four" vertical />
                    <div>
                        <Button color="primary" variant="contained" onClick={handleSubmit}>Create my wealth plan</Button>
                        <Spacer size="two" />
                        <Button variant="outlined" href="https://ursa-capital.com/join" target="_blank">Learn more about investing</Button>
                    </div>
                    <Spacer size="four" vertical />
                    <Spacer size="four" vertical />
                </Container>
                <Footer />
          </Box>
        );
    };

    const renderTable = () => {
        if (portfolio) {
          return <PortfolioTable portfolio={portfolio} name={name} />
        }
        return null;
    }

    const renderPage = (page: number) => {
        switch(page){
            case 0:
                return renderForm();
            case 1:
                return renderTable();
            default:
                return <></>
        }
    };

    const renderNavigation = () => {
        if (page === 0 && contactEmail){
            return <header className="Form-app-header" style={{backgroundColor: theme.palette.background.default}}>Hi, {contactEmail}</header>;
        }
        return (
            <HideOnScroll className="header-hide-on-scroll">
                <AppBar position="fixed" className="header">
                    <Toolbar>
                        <Button color="inherit" variant="outlined" onClick={() => {setPage(0)}} type="button">Back</Button>
                        <Spacer size="two" />
                        <Button color="inherit" variant="outlined" onClick={() => { window.print() }}>Print your report</Button>
                        <Spacer size="two" />
                        <Button target="_blank" color="secondary" variant="contained" href="https://ursa-capital.com/join">start investing now</Button>
                    </Toolbar>
                </AppBar>
            </HideOnScroll>
        );
    };

    return (
        <div className="App">
            <CssBaseline />
            <ThemeProvider theme={theme}>
                <Container maxWidth="xl" disableGutters>
                    {contactEmail ? (
                        <>
                            {renderNavigation()}
                            {renderPage(page)}
                        </>
                    ) : (
                        <>
                            {renderLogin()}
                        </>
                    )}
                </Container>
            </ThemeProvider>
        </div>
    );
}

export default App;
