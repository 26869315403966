import {useState, useEffect} from "react";
import axios from 'axios';

const LOCAL_STORAGE_KEY = 'conveyor_belt_wealth_email'
const URI = 'https://ursa-capital.com/api/conveyor-belt-signup'

type UseContactEmailResult = [string | undefined, (value: string) => Promise<void>, boolean];

export const useContactEmail = (): UseContactEmailResult => {
    const [contactEmail, setContactEmail] = useState<string>();
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        const fromStorage = localStorage.getItem(LOCAL_STORAGE_KEY);
        if (fromStorage){
            setContactEmail(fromStorage);
        }
    }, []);

    const handleSetContactEmail = async (value: string) => {
        setLoading(true);
        await axios.post(URI, {
            email: value,
        });
        localStorage.setItem(LOCAL_STORAGE_KEY, value);
        setContactEmail(value);
        setLoading(false);
    };

    return [contactEmail, handleSetContactEmail, loading];
};