import React from 'react';
import {useTheme} from "@mui/material";

const Footer = () => {
    const theme = useTheme()

    return (
        <footer style={{
            fontSize: 10,
            color: '#888888',
            fontStyle: 'italic',
            padding: theme.spacing(2),
            margin: 'auto',

            textAlign: 'left'
        }}>
            <div>Conveyor Belt Wealth and the Conveyor Belt Wealth simulator are copyright {new Date().getFullYear()} Ursa
                Capital Holdings, LLC.
            </div>
            <div>
                The information provided by Conveyor Belt Wealth is intended for educational purposes only and should not be considered as legal, financial, or professional advice. The content presented is designed to offer general information and guidance on various topics.
                It is important to note that Conveyor Belt Wealth and its creators are not lawyers, Certified Public Accountants (CPAs), or licensed professionals in the legal or financial fields. Any information, examples, or suggestions provided should not be construed as a substitute for seeking advice from qualified legal, financial, or professional experts.
                Before making any decisions based on the information provided by Conveyor Belt Wealth, we strongly recommend consulting with a licensed attorney, CPA, or other relevant professionals to ensure that your individual circumstances are properly considered and addressed.
                While we strive to provide accurate and up-to-date information, Conveyor Belt Wealth does not warrant or guarantee the accuracy, completeness, or reliability of the information presented. The use of any information from Conveyor Belt Wealth is solely at your own risk.
                By using Conveyor Belt Wealth's educational product, you acknowledge and agree to this disclaimer, and you understand that the content should not be used as a substitute for professional advice. Conveyor Belt Wealth and its creators shall not be held liable for any actions taken or decisions made based on the information provided.
            </div>
            <div>
                Under no circumstances should any material at this site be used or considered as an offer to sell or a
                solicitation of any offer to buy an interest in any investment. Any such offer or solicitation will be made
                only by means of the Confidential Private Offering Memorandum relating to the particular investment. Access
                to information about the investments are limited to investors who either qualify as accredited investors
                within the meaning of the Securities Act of 1933, as amended, or those investors who generally are
                sophisticated in financial matters, such that they are capable of evaluating the merits and risks of
                prospective investments.
            </div>
        </footer>
    )
}

export default Footer;