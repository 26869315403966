import { createTheme } from '@mui/material/styles';

const customTheme = createTheme({
    palette: {
        primary: {
            main: '#0076EB',
            contrastText: '#FFFFFF', // White text on primary color
        },
        secondary: {
            main: '#00BF6F', // Bright secondary color
            contrastText: '#FFFFFF',
            light: '#EAF9F0',
        },
        error: {
            main: '#FF3131'
        },
        background: {
            default: '#FFFFFF',
        },
        // Add more palette colors like error, warning, info, and success if needed
    },
    typography: {
        fontFamily: 'Roboto, sans-serif', // Set default font
        h1: {
            fontSize: '2.5rem', // Large headings
            fontWeight: 600,
        },
        h2: {
            fontSize: '2rem', // Subheadings
            fontWeight: 500,
        },
        body1: {
            fontSize: '1rem', // Regular body text
        },
        // Add more typography variants like h3, h4, body2, etc.
    },
    spacing: 8, // Set spacing unit for consistent spacing
});

export default customTheme;
